import mock from 'src/utils/mock';
import type { Application } from 'src/types/application';

let applications: Application[] = [
  {
    external_reference_id: "100925",
    name: "Mukeem Mani",
    product_name: "Health Premium",
    mh_decision: "STP",
    risk_class: "Sub-Standard",
    status: "in_progress",
    confidence: "MEDIUM",
    application_assigned_to: "Sangeeta R.",
    uuid:"uuid",
    member:"sample",
    decision:"uw_accepted"
  },
  
];

mock.onGet('/api/applications').reply(200, { applications });
