import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { useLocation} from 'react-router-dom';
import {NavigationContext} from "../contexts/NavigationContext"
interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location= useLocation();
  var lastPath= location.pathname.slice(location.pathname.lastIndexOf("/")+1,location.pathname.length);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
    }
  return (
    <>
    <NavigationContext.Provider value={lastPath}  >
      {children}
      </NavigationContext.Provider>
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
