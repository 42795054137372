import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ForgotPassword from 'src/views/auth/ForgotPassword/forgotpassword';
import ResetPassword from 'src/views/auth/ResetPassword/ResetPassword';
import { debug } from 'console';
import { UserCheck } from 'react-feather';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (
    email: string,
    first_name: string,
    last_name: string,
    password1: string,
    password2: string
  ) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (
    uid: string,
    token: string,
    new_password1: string,
    new_password2: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type PasswordResetAction = {
  type: 'PASSWORDRESET';
  payload: {
    user: User;
  };
};
type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | PasswordResetAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (access_token: string): boolean => {
  if (!access_token) {
    return false;
  }

  const decoded: any = jwtDecode(access_token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (access_token: string | null): void => {
  if (access_token) {
    localStorage.setItem('access_token', access_token);
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  } else {
    localStorage.removeItem('access_token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      
      const { isAuthenticated, user } = action.payload;
     
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'PASSWORDRESET': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();

  const login = async (email: string, password: string) => {
  try {
    const response = await axios.post<{
      access_token: string;
      user: User;
    }>(process.env.REACT_APP_BACKEND_URL + `/auth/login/`, { email, password });
    const { access_token, user } = response.data;

    setSession(access_token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  } catch (err) {
    return err.response.data.error;
  }
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, first_name: string, last_name:string, password1:string,password2:string) => {
  try{
    const response = await axios.post<{user: User }>(`${process.env.REACT_APP_BACKEND_URL}/register/` , {
      email,
      first_name,
      last_name,
      password1,
      password2
    });
    const {user } = response.data;
    history.push('/Registration-complete');
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  } catch (err) {
    return err.response.data.email;
  }
   
  };

  const forgotPassword = async (email: string) => {
    
    const response = await axios.post<{ user: User}>(
      process.env.REACT_APP_BACKEND_URL + `/auth/password/reset/`,
      { email }
    );
    
    const { user } = response.data;
    //   history.push('/Registration-complete');
    dispatch({
      type: 'PASSWORDRESET',
      payload: {
        user
      }
    });
  };
  const resetPassword = async (
    uid: string,
    token: string,
    new_password1: string,
    new_password2: string
  ) => {
    const response = await axios.post<{detail:string}>(
      process.env.REACT_APP_BACKEND_URL + `/auth/password/reset/confirm/`,
      {  uid, token, new_password1, new_password2 }
    );
    const { detail } = response.data;
    if(detail){
      history.push('/login');
    }
    //  history.push('/Registration-complete');
  };
  useEffect(() => {
    const initialise = async () => {
      try {
        const access_token = window.localStorage.getItem('access_token');

        if (access_token && isValidToken(access_token)) {
          setSession(access_token);
          const response = await axios.get<{ user: User; }>(  process.env.REACT_APP_BACKEND_URL + `/user/?current_user=true`);
          const { user } = response.data;
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        forgotPassword,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;