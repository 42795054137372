import React, { useContext } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Grid,
  Typography,
  makeStyles,
  Breadcrumbs,
  Link
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import type { Theme } from 'src/theme';
import {NavigationContext} from 'src/contexts/NavigationContext';

interface HeaderProps {
  className?: string;
  applicantName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  header:{
textTransform:'capitalize'
  },
}));

const Header: FC<HeaderProps> = ({ className, applicantName, ...rest }) => {
  const classes = useStyles();
  const location:any= useLocation();
  const name= location.state?.name ? location.state.name:"";
  const NavigationName = useContext(NavigationContext);
 let Header= name? "Applications" : NavigationName;
  

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
      <Breadcrumbs
          separator={name&&<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/app/applications"
            component={RouterLink}
            className={classes.header}
          >
           {Header}
          </Link>
          <Typography
            variant="body1"
            color="textPrimary"
          >            
         {name}
          </Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  applicantName: PropTypes.string
};

export default Header;
