import mock from 'src/utils/mock';
import type { InsuranceProductStatistic } from 'src/types/insuranceProductStatistic';

let products: InsuranceProductStatistic[] = [
  {
    name: "Health Premium",
    submitted: "1176",
    issued: "978",
    issued_percent: 83,
    pending: "198",
    pending_percent: 17,
    counterOffer: "46",
    percentCounterOffer: "3.91%",
    declined: "27",
    declined_percent: 2.30
  },
  {
    name: "Health Premium",
    submitted: "1176",
    issued: "978",
    issued_percent: 83,
    pending: "198",
    pending_percent: 17,
    counterOffer: "46",
    percentCounterOffer: "3.91%",
    declined: "27",
    declined_percent: 2.30
  },
  {
    name: "Health Premium",
    submitted: "1176",
    issued: "978",
    issued_percent: 83,
    pending: "198",
    pending_percent: 17,
    counterOffer: "46",
    percentCounterOffer: "3.91%",
    declined: "27",
    declined_percent: 2.30
  },
  {
    name: "Health Premium",
    submitted: "1176",
    issued: "978",
    issued_percent: 83,
    pending: "198",
    pending_percent: 17,
    counterOffer: "46",
    percentCounterOffer: "3.91%",
    declined: "27",
    declined_percent: 2.30
  }
];

mock.onGet('/api/products-statistic').reply(200, { products });
