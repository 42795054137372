import mock from 'src/utils/mock';
import type { ApplicationDetail } from 'src/types/applicationDetail';

let applicationDetail: ApplicationDetail = {
    applicationNo: "100925",
    first_name: "Mukeem Mani",
    occupation: "Desk Worker",
};

mock.onGet('/api/applicationDetail').reply(200, { applicationDetail });
