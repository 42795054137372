import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    completed: {
      display: 'inline-block',
    },
    stepper:{
      backgroundColor:"#F9FCFF"
    },
    stepCompleted: {
      color: 'green',
    },
    stepIcon: {
      color: '#A2A4A4',
      "&$activeIcon": {
        color: '#7824DD',
      },
      "&$completedIcon": {
        color: '#36424A',
      }
    },
    activeIcon: {
      color: '#7824DD',
    },
    completedIcon: {
      color: '#36424A',
    },
  })
);

function getSteps() {
  return ['Received', 'In Progress', 'Decision'];
}

interface StepperProps {
  className?: string;
  applicationDetails?: object;
  uuid?: string;
  StepperValue?: number;
}
const HorizontalLinearStepper: FC<StepperProps> = ({ StepperValue }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  //const [isFlag, setFlag] = React.useState<boolean>(false);
  const location: any = useLocation();
  const status = location.state?.status ? location.state.status : '';
  const isFlag = status.length > 1 ? true : false;
  if (status === 'new') {
    StepperValue = 1;
  } else {
    if (status === 'in_progress') {
      StepperValue = 2;
    } else {
      StepperValue = 3;
    }
  }
 
  const steps = getSteps();
  const getStatus = StepperValue => {
    setActiveStep(StepperValue);
  };

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  useEffect(() => {
    getStatus(StepperValue);
  }, [getStatus]);

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
  {isFlag && (<div className={classes.root}>
      <div className="container">
        <div className="row">
          <div className="col-md-4" >
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode } = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption"></Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}
                      StepIconProps={{
                      classes: {
                        root: classes.stepIcon,
                        active: classes.activeIcon, completed: classes.completedIcon
                      }
                    }}
                    >{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
      </div>
    </div>) }</>
  );
};
export default HorizontalLinearStepper;
