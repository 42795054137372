import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const LogoLight: FC<LogoProps> = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/mh_logo_white.svg"
      {...props}
    />
  );
}

export default LogoLight;
