import React from 'react';
import { Link, useParams } from 'react-router-dom';
import LogoLight from 'src/components/LogoLight';
import type { Theme } from 'src/theme';
import Account from './Account';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import useAuth from 'src/hooks/useAuth';
import HorizontalLabelPositionBelowStepper from './stepper';
import {
  makeStyles,
  useTheme,
  createStyles
} from '@material-ui/core/styles';
import { Home,Layers } from 'react-feather';
import { Box, Grid } from '@material-ui/core';
import Header from './Header';
import { ApplicationDetail } from 'src/types/applicationDetail';

const DRAWER_WIDTH = 80;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: DRAWER_WIDTH,
        flexShrink: 0
      }
    },
    logoWrapper: {
      width: 80,
      '& > img': {
        maxWidth: '80%',
      }
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: DRAWER_WIDTH, backgroundColor: '#F9FCFF',
      }
    },
    whiteIcon: {
      fontSize: 30,
      color: theme.palette.common.white,
    },
    iconText: {
      color: 'theme.palette.common.white',
      padding: theme.spacing(0.625),
    },
    linkWrapper: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      '&:active': {
        textDecoration: 'none',
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: DRAWER_WIDTH,
      backgroundColor: theme.palette.grey.A400
    },
    loginprofile: {
      position: 'fixed',
      bottom: 20,
      left: 20
    },
    content: {
      flexGrow: 1,
      padding: 0
    }
  })
);

interface SideBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  window?: () => Window;
}

export default function SideBar(props: SideBarProps) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useAuth();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { applicationNo } = useParams();
  const [application, setApplication] = React.useState<ApplicationDetail>({
    applicationNo: applicationNo,
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box display="flex" flexDirection="column" alignItems="center" height="100%" >
      <Box marginBottom="50px" marginTop="20px" textAlign="center">
        <Link to="/" className={classes.logoWrapper}>
          <LogoLight />
        </Link>
      </Box>
      <Box marginBottom="30px">
        { user.is_staff  &&<Link to="/app/analytics" data-toggle="tooltip" title="Analytics View" className={classes.linkWrapper}>
          <Home  className={classes.whiteIcon}></Home>
        </Link>} 
      </Box>
      <Box>
      {(user.is_staff || !user.is_staff)&&<Link to="/app/applications"data-toggle="tooltip" title="Applications List" className={classes.linkWrapper}>
          <Layers className={classes.whiteIcon}></Layers >
        </Link>} 
      </Box>
      <Box flexGrow={1} />
      <Box>
        <Account  />
      </Box>
    </Box>
  ); 

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
       
        <Toolbar >
        <Grid item sm={8}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}  
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
<Header applicantName={application.first_name  || "Unknown"} />
</Grid>
    
<Grid item sm={4}>
<HorizontalLabelPositionBelowStepper StepperValue={0}/>
    
        </Grid>
        </Toolbar>
   
      </AppBar>
      <nav className={classes.drawer} aria-label=" folders">
        <Hidden smUp >
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown >
          <Drawer classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
      </main>
    </div>
  );
}
